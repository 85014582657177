












































































import { Vue, Component, Watch } from "vue-property-decorator";
import TablaGeneral from "@/components/General/TablaGeneral.vue";
import {
  Cuadrilla,
  GuardarCuadrilla,
  ObtenerCuadrilla,
  ObtenerTipoCuadrilla,
  TipoCuadrilla,
  EditarCuadrillaEstado,
  EditarCuadrilla,
} from "@/entidades/Plantacion/Cuadrilla";
import Contratista from "@/entidades/Plantacion/Contratista";
import ResponseGenerico from "@/entidades/ResponseGenerico";
import { Mutation, State } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";

@Component({
  name: "CuadrillaView",
  components: {
    TablaGeneral,
  },
})
export default class CuadrillaView extends Vue {
  @State("sistemaId", { namespace: "authModule" }) sistemaId!: number;
  @Mutation("mostrarLoading", { namespace: "sistemaModule" })
  mostrarLoading!: Function;
  @Mutation("mostarAlert", { namespace: "sistemaModule" })
  mostarAlert!: Function;
  //Propiedas y atributos
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Descripcion", sortable: true, value: "descripcion" },
    { text: "Tipo Cuadrilla", sortable: true, value: "tipoCuadrillaName" },
    { text: "Editar", sortable: false, value: "actions" },
    { text: "Estado", sortable: true, value: "estado" },
  ];

  modalAgregar: boolean = false;
  search: string = "";
  listado: Cuadrilla[] = [];
  listadoTipoCuadrilla: TipoCuadrilla[] = [];
  listadoContratista: Contratista[] = [];
  descripcion: string = "";
  tipoCuadrillaId: number = 0;
  contrastitaId: number | null | undefined = 0;
  accion: number = 1;
  cuadrillaId: number = 0;

  async ObtenerTipoCuadrila() {
    try {
      let response = await ObtenerTipoCuadrilla();
      if (response.isSuccess == true) {
        this.listadoTipoCuadrilla = response.isResult;
        this.modalAgregar = true;
        return;
      } else
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
    } catch (error) {
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    }
  }
  abrilModal() {
    this.ObtenerTipoCuadrila();
  }
  modalEditar(cuadrilla: Cuadrilla) {
    console.log(cuadrilla);
    this.cuadrillaId = cuadrilla.cuadrillaId;
    this.descripcion = cuadrilla.descripcion;
    this.tipoCuadrillaId = cuadrilla.tipoCuadrillaId;
    this.contrastitaId = cuadrilla.contratistaId;
    this.accion = 2;
    this.ObtenerTipoCuadrila();
  }

  async Obtener() {
    try {
      this.mostrarLoading(new Loading(true, "Obteniendo registros"));
      let response = await ObtenerCuadrilla();
      if (response.isSuccess == true) {
        this.listado = response.isResult;
        return;
      }
      this.$store.commit("alert", {
        position: "center",
        timeout: 1000,
        text: response.isMessage,
        color: "red",
      });
    } catch (error) {
      this.$store.commit("alert", {
        position: "center",
        timeout: 1000,
        text: error.message,
        color: "red",
      });
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }
  async guardar() {
    try {
      this.mostrarLoading(new Loading(true, "Registrando informacion"));
      let response = new ResponseGenerico(false, "");
      let contId = this.contrastitaId == 0 ? null : this.contrastitaId;
      let cuadrilla = new Cuadrilla(
        this.cuadrillaId,
        this.descripcion,
        true,
        this.tipoCuadrillaId,
        contId
      );

      response =
        this.accion == 1
          ? await GuardarCuadrilla(cuadrilla)
          : await EditarCuadrilla(cuadrilla);
      if (response.isSuccess == true) {
        this.LimpiarClose();
      }

      var color = response.isSuccess ? "info" : "red";

      this.$store.commit("alert", {
        position: "center",
        timeout: 1000,
        text: response.isMessage,
        color: color,
      });
    } catch (error) {
      this.$store.commit("alert", {
        position: "center",
        timeout: 1000,
        text: error.message,
        color: "red",
      });
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async EditarEstado(cuadrilla: Cuadrilla) {
    try {
      let response = await EditarCuadrillaEstado(cuadrilla);
      if (response.isSuccess == true) this.LimpiarClose();

      let color = response.isSuccess ? "info" : "red";
      this.$store.commit("alert", {
        position: "center",
        timeout: 1000,
        text: response.isMessage,
        color: color,
      });
    } catch (error) {
      this.$store.commit("alert", {
        position: "center",
        timeout: 1000,
        text: error.message,
        color: "red",
      });
    }
  }

  LimpiarClose() {
    this.descripcion = "";
    this.tipoCuadrillaId = 0;
    this.modalAgregar = false;
    this.Obtener();
  }

  get Mensaje() {
    if (this.accion == 1)
      return { titulo: "Registro de Cuadrilla", action: "GUARDAR" };
    else return { titulo: "Ediccion de Cuadrilla", action: "EDITAR" };
  }

  get Habilitar() {
    if (this.descripcion == "") return false;
    else return true;
  }

  //WATCH
  @Watch("tipoCuadrillaId")
  async validarTipoCuadrilla(val: number, old: number) {
    if (val == 2) {
      try {
        let response = await Contratista.Obtener(this.sistemaId);
        if (response.isSuccess == true) {
          this.listadoContratista = response.isResult;
        }
      } catch (error) {}
    }
  }
  mounted() {
    this.Obtener();
  }
}
